<template>
  <div v-lazyload class="image__wrapper">
    <div class="image__loading">
      <svg class="spinner" viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
      </svg>
    </div>
    <img
    :data-url="src"
    src=""
    :alt="alt"
    class="image__item"
    @error="emitError">
  </div>
</template>

<script>
import LazyLoadDirective from '@/utils/directives/LazyLoadDirective';

export default {
  name: 'LazyLoadImage',
  props: {
    src: String,
    alt: String,
  },
  directives: {
    lazyload: LazyLoadDirective,
  },
  methods: {
    emitError(event) {
      this.$emit('error', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &.loading {
      .image {
        &__loading {
        .spinner {
          animation: loading-rotate 2s linear infinite;
          height: 50px;
          width: 50px;
        }

        .path {
          animation: loading-dash 1.5s ease-in-out infinite;
          stroke-dasharray: 90, 150;
          stroke-dashoffset: 0;
          stroke-width: 2;
          stroke: $secondary;
          stroke-linecap: round;
        }
      }
      }
    }

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
          width: auto;
        }

        &__loading {
          display: none;
        }
      }
    }
  }

  &__item {
    height: inherit;
    width: 0;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  @keyframes loading-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes loading-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -40px;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -120px;
    }
  }
}
</style>
