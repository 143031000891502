import trim from 'lodash/trim';
/* ---------------------- validate functions (RegEx) -------------------------- */
const emailRegex = (str) => {
  const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(str)
    .toLowerCase());
};

const mobileRegex = (str) => {
  const re = /^(?:\+?61|0)4[0-9]{8}$/;
  return re.test(String(str)
    .toLowerCase());
};

const posIntRegex = (str) => {
  const re = /^[1-9]\d+$/;
  return re.test(String(str)
    .toLowerCase());
};

const dateRegex = (str) => {
  // Invalid date -- Passed from the mask pkg
  if (str === 'Invalid date') return true;
  const re = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
  return re.test(String(str)
    .toLowerCase());
};

/* ----------------------  Validators  ---------------------------------------- */

/**
 * Base validator strategy for all strategies.
 *
 * Note: frontend error message is disregarded for the moment, but they are kept for future use.
 *
 * @param data  {string | boolean}  Form.Field.Value
 * @param func  {function}          Validator function which
 *                                  should return a Boolean based of successful/not
 * @param msgError {string}         Error message
 *
 * @returns {boolean | string}      True | Error message
*/
const strategy = (data, func, msgError) => func(data) || msgError;

export const emailStrategy = data => strategy(
  data, emailRegex, 'Email is invalid',
);

export const mobileStrategy = data => strategy(
  data, mobileRegex, 'Mobile is invalid',
);

export const emailStrategyOptional = data => strategy(
  data, (d) => { if (d === undefined || d === '') return true; return emailRegex(d); }, 'Email is invalid',
);

export const mobileStrategyOptional = data => strategy(
  data, (d) => { if (d === undefined || d === '') return true; return mobileRegex(d); }, 'Mobile is invalid',
);

export const positiveIntStrategy = data => strategy(
  data, posIntRegex, 'Input is invalid',
);

export const dateStrategy = data => strategy(
  data, dateRegex, 'Date is invalid',
);

export const choiceStrategy = options => data => strategy(
  data, value => options && options.filter(option => option.value === value).length !== 0,
  'Invalid choice',
);

export const requiredStrategy = data => strategy(
  data, (x) => {
    const newStr = trim(x);
    if (x === false) return true;
    return !!newStr;
  }, 'Field is required',
);

export const inValidNamesStrategy = (data) => strategy(
  data,
  (name) => name === '' || /^[A-Za-z]+$/.test(name),
  'Name must contain only alphabetic characters with no spaces, numbers, or special characters.',
);

export default strategy;
