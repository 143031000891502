export default {
  inserted: (elem) => {
    function startSpinner() {
      const imageElement = Array.from(elem.children).find(
        el => el.nodeName === 'IMG',
      );
      if (imageElement) {
        setTimeout(() => elem.classList.add('loading'), 300);
      }
    }
    function loadImage() {
      const imageElement = Array.from(elem.children).find(
        el => el.nodeName === 'IMG',
      );
      if (imageElement) {
        imageElement.addEventListener('load', () => {
          setTimeout(() => elem.classList.add('loaded'), 100);
        });
        imageElement.src = imageElement.dataset.url;
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startSpinner();
          loadImage();
          observer.unobserve(elem);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: '0',
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(elem);
    }
    if (window.IntersectionObserver) {
      createObserver();
    } else {
      loadImage();
    }
  },
};
