<template>
  <div class="form-control">
    <VueGoogleAutocomplete
      :id="id"
      :classname="hasText ? `has-text ${classname}` : classname"
      :enableGeolocation="enableGeolocation"
      placeholder
      @placechanged="placechanged"
      @inputChange="inputChanged"
    />
    <label :for="id">{{placeholder}}</label>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
  name: 'AddressAutocomplete',
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    id: String,
    classname: String,
    enableGeolocation: Boolean,
    placeholder: String,
  },
  data() {
    return {
      hasText: false,
    };
  },
  methods: {
    placechanged(addressData) {
      this.$emit('placechanged', addressData);
    },
    inputChanged({ newVal }) {
      this.hasText = newVal !== '';
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border: none;
  position: relative;
  padding: 0;

  ::v-deep #address-autocomplete {
    padding: 25px 12px 6px 0 !important;
    height: 45px !important;
    font-size: 0.875rem;
    color: #098255;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    box-shadow: none !important;
    transition: border-color 0.5s;
    width: 100%;
  }

  label {
    position: absolute;
    bottom: 5px;
    left: 0;
    color: #909090;
    font-weight: 300;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
  }

  ::v-deep input:focus + label,
  .has-text + label {
    font-weight: 700;
    font-size: 12px;
    transform: translate3d(0, -1.1rem, 0);
  }

  ::v-deep input:focus + label {
    color: $secondary;
  }
}
</style>
