/* eslint-disable import/prefer-default-export */

/**
* StatEdge db store all fiscal value in cents integer.
* NSW discount is in whole dollar, but will be normalized by API into cents.
*
* @param
*   - Number {cents}          12345
* @return
*   - String {dollar decimal} $123.45
*/
export const formatMoney = cents => (cents > 0 ? `$${cents / 100}` : '$0');
