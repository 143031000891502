import moment from 'moment';

/* Because the API takes dates in the dash format
 * and frontend takes Australian slash format,
 * functions are provided here to make them able to transform back and forth
*/

export const dateSlashToDash = date => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');

export const dateDashToSlash = date => moment(date).format('DD/MM/YYYY');
