import msg from '@/utils/constants/msg';
import validate from '@/utils/validators';
import { dateStrategy, requiredStrategy } from '@/utils/validators/strategy';
import { dateDashToSlash, dateSlashToDash } from '@/utils/date/transform';
import { fromEntries } from '@/utils/misc';

/* Form utilities for form <==> data manipulation. */

/**
 * Helper to make a form function like how it should.
 * 1. {AnyField}  Inject value / errors array in form objects,
 *                which will be used to sync {inputVal}, and {error} Array[]
 *                Inject default {RequiredValidator}, unless specify `required = false`
 *
 * 2. {DateField} Inject default {DateValidator}, specify forward and backward {DateTransformer}
 *
 * @param  form: Form with `partial fields`
 * @return form with `complete fields` = {
 *    label,
 *    value,
 *    errors,
 *    validators,
 *    transformers, // For API communication
 * }
 */
export const makeForm = form => fromEntries(
  Object.entries(form).map(
    ([k, v]) => {
      const validators = [];
      // default strategy includes `required`
      if (v.required !== false) validators.push(requiredStrategy);
      validators.push(...v.validators || []);

      // inject transformers for displaying the values
      const transformers = v.component === 'DateInput' ? {
        flattenTransformer: dateSlashToDash,
        populateTransformer: dateDashToSlash,
      } : {};

      if (v.component === 'DateInput') validators.push(dateStrategy);

      return [k, {
        value: '',
        errors: [],
        validators,
        ...transformers,
        ...v,
      }];
    },
  ),
);

/**
* Primary validation function to validate a form.
* Usually called immediately on form `submit()`
* @example
*   - if (!errorOrPass(form, [], this)) return;
*
* @param
*   form: Form
*   skip: Fields to skip validation
*   vm: the Vue instance, in Vue object, should pass `this`
*
* @return
*   PASS: boolean
*
* @side-effects
*   true  - raise an alert on validation failure
*   false - allow process to continue if success
*/
export const errorOrPass = (form, skip = [], vm, window) => {
  const summedErrors = [];
  // DEV ONLY
  // if (process.env.NODE_ENV === 'development') console.log('Validating...', form);
  Object.keys(form).forEach(
    (k) => {
      const field = form[k];
      if (!field) return;
      // skip if field is explicitly skipped
      if (skip && skip.includes(k)) return;
      // skip if field is not active due to some logic (not displayed)
      if (field.displayed && field.displayed() === false) return;
      field.errors = validate(field.validators, field.value);
      if (field.errors.length !== 0) summedErrors.push([k, field.errors]);
    },
  );

  const PASS = summedErrors.length === 0;

  if (!PASS) {
    // DEV ONLY
    // if (process.env.NODE_ENV === 'development') console.log('dev error list', summedErrors);
    if (vm) {
      vm.$store.dispatch('views/notify', {
        msg: msg.error.validationError,
        type: 'warning',
        window,
      });
    }
  }

  return PASS;
};

/* batch version of above, allowing the whole form to be skipped */
export const errorOrPassFormset = (forms, skip = [], vm, window) => {
  let PASS = true;
  Object.entries(forms).forEach(
    ([name, form]) => {
      // allow the whole form to be skipped
      if (skip && skip.includes(name)) return;

      // below ensures that notify is only passed once.
      // on first failure, PASS will be flagged to `false`, and vm won't be passed, so notify won't
      // trigger. Meanwhile, the dev error list will still work.
      if (!errorOrPass(form, null, PASS ? vm : null, window)) {
        PASS = false;
      }
    },
  );
  return PASS;
};

/**
* Flatten a form.
*
* @param
*   - Form: {
*       field1: {
*         value: {VALUE_HERE},  // KEEP
*         errors,               // discard everything else
*         ...
*       },
*       field2: {...},
*   }
*
* @return
*   - FlattenedForm: {
*     field1: {VALUE_HERE}
*   }
*/
export const flatten = (form) => {
  const flattened = {};

  Object.keys(form).forEach(
    (k) => {
      const field = form[k];
      if (field.displayed && field.displayed() === false) return;
      flattened[k] = field.value;

      if (field.flattenTransformer) {
        flattened[k] = field.flattenTransformer(flattened[k]);
      }
    },
  );

  return flattened;
};

/* Batch version of above that flattens many forms nested as a FormSet */
export const flattenFormset = (formset) => {
  const flattened = {};

  Object.keys(formset).forEach(
    (k) => {
      flattened[k] = flatten(formset[k]);
    },
  );

  return flattened;
};

/**
 * Reverse of `flatten`.
 *
 * If a [key:value] is in data but not in `form`, it is disregarded.
 *
*/
export const populate = (form, data) => {
  const newForm = form;
  if (!data) return form;
  Object.keys(data).forEach(
    (k) => {
      if (!newForm[k] || newForm[k].value === undefined) return; // return if field is not present
      newForm[k].value = data[k] !== undefined ? data[k] : '';

      if (newForm[k].populateTransformer) {
        newForm[k].value = newForm[k].populateTransformer(newForm[k].value);
      }
    },
  );
  return newForm;
};

/* Batch version of above that populates many forms nested as a FormSet */
export const populateFormset = (formset, data) => {
  const newFormset = formset;
  Object.keys(data).forEach(
    (k) => {
      if (!newFormset[k] || !data[k]) return; // return if form is not present
      newFormset[k] = populate(newFormset[k], data[k]);
    },
  );
  return newFormset;
};
