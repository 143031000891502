<template>
  <button class="d-flex button-no-style" @click.prevent="() => {}">
    <div class="mr-1">
      <lazy-load-image
        :src="`${img}?ts=${now}` || '/images/nrl.svg'"
        @error="imgUrlAlt"
        :alt="title || name"
        class="logo"
      />
    </div>
    <div class="d-flex-column">
      <div>
        <h3 class="title">{{ title || name }}</h3>
      </div>
      <div>
        <h4 id="subtitle" class="subtitle">{{ subtitle || "" }}</h4>
      </div>
    </div>
  </button>
</template>

<script>
import LazyLoadImage from "./LazyLoadImage.vue";

export default {
  name: "LogoTitleSubtitle",
  props: {
    title: String,
    subtitle: String,
    img: String,
    name: String,
  },
  components: {
    LazyLoadImage,
  },
  data() {
    return {
      now: Date.now(),
    };
  },
  methods: {
    imgUrlAlt(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = "/images/nrl.svg";
    },
  },
};
</script>

<style lang="scss" scoped>
.title,
.subtitle {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-family: $fontMedium;
}

.subtitle {
  color: #717171;
}

.logo {
  margin-right: 5px;
  height: 60px;
  width: 60px;
}
</style>
