<template>
  <div class="d-flex">
    <label class="checkbox">
      <input type="checkbox"
             :value="val"
             v-model="checked"
             @change="onChange"
             ref="checkbox"
      >
      <span class="checkmark"></span>
    </label>
    <div @click="() => $refs.checkbox.click()" class="clickable">
      <slot></slot>
    </div>
  </div>

</template>

<script>
// references:
// https://forum.vuejs.org/t/using-v-model-with-checkbox-in-custom-component/3906/5
// https://forum.vuejs.org/t/custom-checkbox-component/1120/11
// https://jsfiddle.net/robertkern/oovb8ym7/
export default {
  name: 'CheckBoxLine',
  components: {},
  props: ['value', 'val', 'content'],
  data() {
    return {
      checkedProxy: false,
    };
  },

  methods: {
    onChange() {
      this.$emit('input', this.checkedProxy);
    },
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedProxy = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 0;
}
</style>
